import React from "react"

function Disclaimer() {
  return (
    <div className="bg-accentPrimary-light rounded-xl p-9 mt-4">
      <div className="leading-normal text-center text-grey-dark">
        Since the legacy plan you create is designed for you to share, to
        protect personal information, do not enter full social security numbers,
        government IDs, financial account numbers or any sensitive medical data
        in the form text fields.
      </div>
    </div>
  )
}

export default Disclaimer
