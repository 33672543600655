import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import SidebarLayout from "../../components/sidebar/layout"
import Topic from "../../components/topic"
import { useStaticQuery, graphql, Link } from "gatsby"
import { useQuery } from "@apollo/client"
import { useUser } from "../../context/user-context"
import LoadingAnimation from "../../components/loading-animation"
import { GET_CHECKLIST } from "../../graphql/queries/checklist"
import { QUERY_GET_COLLABORATORS } from "../../graphql/queries/team"
import Section from "../../components/plan/section"
import UpgradeFlow from "../../components/checkout/upgrade-flow"
import UpgradePaywallModal from "../../components/checkout/upgrade-paywall-modal"
import ErrorBoundary from "../../components/error-boundary"
import TasksSearch from "../../components/search/tasks-search"
import Task from "../../components/task/task"
import NoResults from "../../components/search/no-results"
import AddTaskModal from "../../components/add-task-modal"
import Files from "../../components/plan/files"
import UploadButton from "../../components/pre-planning/upload-button"
import Onboarding from "../../components/plan/onboarding"
import Disclaimer from "../../components/disclaimer";

function PlanChecklists({ planId }) {
  const { allPrismicPlanPage } = useStaticQuery(graphql`
    query PlanPage {
      allPrismicPlanPage {
        edges {
          node {
            data {
              title {
                html
                text
              }
              description {
                text
                html
              }
              invite_collaborators_title {
                html
                text
              }
              user_team_title {
                text
                html
              }
              user_team_description {
                text
                html
              }
              user_team_disclaimer {
                text
                html
              }
            }
          }
        }
      }
    }
  `)

  const [isAddTaskModalVisible, setIsAddTaskModalVisible] = useState(false)
  const [isUpgradeFlowVisible, setIsUpgradeFlowVisible] = useState(false)
  const [allTopics, setAllTopics] = useState(null)
  const [plan, setPlan] = useState(null)
  const [resultCount, setResultCount] = useState(null)
  const [searchResults, setSearchResults] = useState(null)
  const [isAdmin, setIsAdmin] = useState(false)
  const user = useUser()

  const { invite_collaborators_title } = allPrismicPlanPage.edges[0].node.data

  const {
    data: checklist,
    loading,
    refetch,
  } = useQuery(GET_CHECKLIST, {
    variables: { id: planId },
  })

  const { data: collaborators } = useQuery(QUERY_GET_COLLABORATORS, {
    variables: { plan_id: planId },
  })

  useEffect(() => {
    if (checklist) {
      // Get topics and plan from checklist.
      // @TODO - is there anything we can do about this nesting. "checklist.checklist" is confusing to read.
      const { topics, plan } = checklist.checklist
      setAllTopics(topics)
      setPlan(plan)

      if (plan && collaborators) {
        setIsAdmin(
          user.id === plan.user_id ||
            !collaborators?.getCollaborators?.team_members?.filter(
              (member) =>
                member.role === "Viewer" && member.email === user.email
            ).length > 0
        )
      }
    }
  })

  // Add a task modal
  const renderAddATaskModal = (topics) => {
    return topics
      .filter((topic) => topic.categories?.[0]?.tasks?.length > 0)
      .map((topic) => (
        <div key={topic.id}>
          {isAddTaskModalVisible && (
            <AddTaskModal
              plan={plan}
              closeModal={() => setIsAddTaskModalVisible(false)}
              topic={topic}
              topics={allTopics}
              showPaywallModal={() => {
                setIsAddTaskModalVisible(false)
                setIsUpgradeFlowVisible(true)
              }}
              refetch={() => refetch()}
            />
          )}
        </div>
      ))
  }

  const renderAllTopics = (topics) => {
    return topics
      .filter((topic) => topic.categories?.[0]?.tasks?.length > 0)
      .map((topic) => (
        <div className="w-full lg:w-1/4 pb-4 px-2" key={topic.id}>
          <React.Fragment key={topic.id}>
            <Topic plan={plan} topic={topic} />
          </React.Fragment>
        </div>
      ))
  }

  const renderSearchResults = (results) => {
    return results.map(
      (result) =>
        result?.tasks?.length > 0 && (
          <React.Fragment key={result.topicName}>
            <h2
              key={result.topicName}
              className="text-2xl md:text-3xl font-extrabold w-full pt-12 pb-2"
            >
              {result.topicName}
            </h2>
            {result.tasks?.map((task) => (
              <Task
                task={task}
                key={task.id}
                isAdmin={isAdmin}
                refetch={() => refetch()}
                topic={result.topic}
              />
            ))}
          </React.Fragment>
        )
    )
  }

  // Make sure we have everything we need
  if (loading || !checklist || !plan)
    return (
      <div className="items-center" data-testid="loading-animation">
        <LoadingAnimation />
      </div>
    )

  // NoResults props
  const callToAction = (
    <div className="flex flex-row">
      <p>If you&apos;re not able to find the task you need, try</p>
      <div
        onClick={() => setIsAddTaskModalVisible(true)}
        className="text-primary underline ml-1 cursor-pointer"
      >
        adding your own.
      </div>
    </div>
  )

  return (
    <React.Fragment>
      <div className="flex justify-left">
        <h1 className="text-4xl md:text-5xl leading-tight font-extrabold w-full py-6 md:py-12">
          {plan.name || "Checklist"}
        </h1>
      </div>
      <Section title="Checklist Topics" additionalClasses="py-0">
        {!Number(plan.onboarded) && <Onboarding planId={planId} />}

        <TasksSearch
          allTopics={allTopics}
          setResults={setSearchResults}
          setResultCount={setResultCount}
          hasCategory
        />

        <Disclaimer/>

        <h2 className="text-2xl md:text-3xl font-extrabold w-full pt-4 pb-4">
          Tasks
        </h2>
        <div className="flex flex-row flex-wrap -mx-2">
          {resultCount === 0 ? (
            <>
              <NoResults from="tasks" callToAction={callToAction} />
              {renderAddATaskModal(allTopics)}
            </>
          ) : resultCount > 0 ? (
            renderSearchResults(searchResults)
          ) : (
            <>{renderAllTopics(allTopics)}</>
          )}
        </div>
      </Section>

      <div className="pt-6 pb-12">
        <h2 className="text-2xl md:text-3xl font-extrabold w-full pt-12 pb-2">
          Uploads
        </h2>
        <div className="text-grey-dark leading-normal">
          Add a file or photo to your checklist with the link below.
        </div>
        <Files data={checklist?.checklist?.files} planId={planId} />
        <div className="mt-3">
          <UploadButton
            planId={planId}
            isAfterLoss
            onUpload={() => refetch()}
          />
        </div>
      </div>

      <div className="flex-grow pb-32">
        <h2 className="text-2xl md:text-3xl font-extrabold w-full pt-12 pb-2">
          {invite_collaborators_title.text}
        </h2>
        <span className="md:flex body-text">
          <Link
            to="/app/account/settings#add-collaborator"
            className="text-primary underline"
          >
            Go to your My Accounts page
          </Link>
          <p className="text-grey-dark md:ml-1">
            to add collaborators to your checklist.
          </p>
        </span>
      </div>

      {isUpgradeFlowVisible && (
        <UpgradeFlow
          paywallComponent={UpgradePaywallModal}
          onCancel={() => setIsUpgradeFlowVisible(false)}
          onSuccess={() => {
            setIsUpgradeFlowVisible(false)
          }}
        />
      )}

    </React.Fragment>
  )
}

function PlanPage({ planId }) {
  return (
    <SidebarLayout planId={planId}>
      <ErrorBoundary>
        <PlanChecklists planId={planId} />
      </ErrorBoundary>
    </SidebarLayout>
  )
}

PlanPage.propTypes = {
  planId: PropTypes.string.isRequired,
}

PlanChecklists.propTypes = {
  planId: PropTypes.string.isRequired,
  data: PropTypes.object,
  loading: PropTypes.bool,
}

export default PlanPage
