import React, { useState } from "react"
import PropTypes from "prop-types"
import { useMutation } from "@apollo/client"
import { useAlert } from "react-alert"
import { MUTATION_DELETE_FILE } from "../../graphql/mutations/task"
import { GET_CHECKLIST } from "../../graphql/queries/checklist"
import ConfirmModal from "../../components/modals/confirm-modal"
import UploadedFileIcon from "../svg-components/uploaded-file-icon"
import axios from "axios"
import { getToken } from "../../utils/auth"
function Files({ data, planId }) {
  const alert = useAlert()

  const [isDeleteFileModalVisible, setIsDeleteFileModalVisible] =
    useState(false)

  const [file, setFile] = useState({})

  const [deleteFile, { loading: deleting }] = useMutation(
    MUTATION_DELETE_FILE,
    {
      refetchQueries: [{ query: GET_CHECKLIST, variables: { id: planId } }],
    }
  )

  const imageExtensions = [
    "image/jpg",
    "image/gif",
    "image/bmp",
    "image/png",
    "image/jpeg",
  ]

  const handleDelete = () => {
    if (file?.id) {
      deleteFile({
        variables: { id: file?.id },
      })
        .then(() => {
          alert.success(`We've deleted ${file?.name}.`)
          toggleDelefeFileConfirmModal()
        })
        .catch(() => {
          alert.error("We weren't able to delete the file.")
        })
    }
  }

  const toggleDelefeFileConfirmModal = () => {
    setIsDeleteFileModalVisible(!isDeleteFileModalVisible)
  }

  const handleFile = (file) => {
    setFile(file)
    toggleDelefeFileConfirmModal()
  }

  const onDownloadClick = (event, file) => {
    event.preventDefault()
    alert.success("Downloading...")

    if (!file.id || !planId) {
      return
    }
    console.log(`${process.env.GATSBY_LANTERN_REST_URL}/plan/file/${file.id}/download`);

    axios
      .get(
        `${process.env.GATSBY_LANTERN_REST_URL}/plan/file/${file.id}/download`,
        {
          headers: { Authorization: `Bearer ${getToken()}` }
        }
      )
      .then((response) => {
        if (response.status === 200) {
          window.open(response.data.url, '_blank');
        } else {
          alert.error(
            "Failed to download the file. Please reach out for assistance using the chat widget in the right corner of your screen."
          )
        }
      })
      .catch(() => {
        alert.error(
          "Failed to download the file. Please reach out for assistance using the chat widget in the right corner of your screen."
        )
      })
  }

  return (
    <React.Fragment>
      <div className="w-full">
        {data?.map((file) => (
          <li
            className="flex border-grey-light rounded-md border-2 px-4 py-2 mb-2 text-grey-dark justify-between bg-white"
            key={file?.id}
          >
            <div
              className="flex flex-row"
              onClick={(event) => onDownloadClick(event, file)}
            >
              <div className="flex flex-col justify-center">
                <span className="material-icons align-middle mr-2 text-grey text-lg">
                  {imageExtensions.includes(file.type) ? (
                    "image"
                  ) : (
                    <UploadedFileIcon />
                  )}
                </span>
              </div>
              <div className="flex-grow px-3 p-m-1">
                <p className="text-grey-dark leading-snug text-sm font-semibold cursor-pointer ">
                  {file.name}
                </p>
              </div>
            </div>
            <div className="flex flex-col justify-center w-4">
              <a
                className="material-icons cursor-pointer flex items-center mr-4 text-grey-dark"
                onClick={() => handleFile(file)}
              >
                delete_outline
              </a>
            </div>
          </li>
        ))}
      </div>
      {isDeleteFileModalVisible && (
        <ConfirmModal
          closeModal={toggleDelefeFileConfirmModal}
          title={`Are you sure you want to delete the file?`}
          description="Please make sure you're certain before confirming."
          confirmButtonText={deleting ? "Deleting..." : "Confirm!"}
          cancelButtonText="No, take me back."
          confirmButtonDisabled={deleting}
          onConfirm={handleDelete}
          onCancel={toggleDelefeFileConfirmModal}
        />
      )}
    </React.Fragment>
  )
}

Files.propTypes = {
  data: PropTypes.array.isRequired,
  planId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default Files
