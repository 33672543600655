import React from "react"
import PropTypes from "prop-types"

function Section({ subtitle, children, additionalClasses }) {
  return (
    <div className={`py-6 ${additionalClasses}`}>
      <div className="flex">
        <div className="flex-grow-0">
          <p className="text-base leading-normal md:leading-relaxed text-grey-dark p-0 m-0">
            {subtitle}
          </p>
        </div>
      </div>
      {children}
    </div>
  )
}

Section.propTypes = {
  subtitle: PropTypes.string,
  children: PropTypes.node.isRequired,
  additionalClasses: PropTypes.string,
}

Section.defaultProps = {
  additionalClasses: "",
}

export default Section
