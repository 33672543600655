import React, { useState } from "react"
import PropTypes from "prop-types"
import { graphql, useStaticQuery } from "gatsby"
import { useMutation } from "@apollo/client"
import { MUTATION_TOGGLE_PLAN_ONBOARDED } from "../../graphql/mutations/checklist"
import ConfirmModal from "../modals/confirm-modal"
import parse from "html-react-parser"
import Button from "../button"

function Onboarding({ planId }) {
  const { allPrismicLanternGuideAfterlossOnboard } = useStaticQuery(graphql`
    query LanternGuideAfterlossOnboard {
      allPrismicLanternGuideAfterlossOnboard {
        edges {
          node {
            data {
              title {
                html
                text
              }
              description {
                html
                text
              }
              bg_img {
                alt
                url
              }
              button_text {
                html
                text
              }
              onboarding_done_text {
                html
                text
              }
            }
          }
        }
      }
    }
  `)

  const { title, description, bg_img, button_text, onboarding_done_text } =
    allPrismicLanternGuideAfterlossOnboard.edges[0].node.data
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)
  const [updateOnboarded] = useMutation(MUTATION_TOGGLE_PLAN_ONBOARDED)
  const [showConfirmModal, setShowConfirmModal] = useState(false)

  const handleOnClick = () => {
    setShowConfirmModal(true)
  }

  return (
    <div
      className="onboarding max-w-full mx-auto mb-10"
      style={{ backgroundImage: `url(${bg_img.url})` }}
    >
      <div className="py-6 px-8 text-white">
        <div className="flex flex-row w-full relative">
          <h2 className="text-2xl font-bold leading-tight pb-6 md:pb-3 w-3/4">
            {title.text}
          </h2>
        </div>
        <div className="text-sm leading-normal mb-2 md:mb-4 mr-0 md:mr-24">
          {parse(description.html)}
        </div>
        {showSuccessMessage ? (
          <div className="leading-normal mb-2 mt-4 md:mb-4 md:mt-6">
            {parse(onboarding_done_text.html)}
          </div>
        ) : (
          <Button
            buttonText={button_text.text}
            buttonType="btn in-app -primary"
            additionalClasses="ml-0 mt-4 mb-4"
            handleOnClick={() => handleOnClick(planId)}
            eventName={{
              eventDomain: "App",
              eventLocation: "Plan Page",
              eventAction: "Click",
              eventActionName: "Request Consult",
            }}
          />
        )}

        {showConfirmModal && (
          <ConfirmModal
            closeModal={() => setShowConfirmModal(false)}
            title={button_text.text}
            description={`A Lantern Guide is a real person who provides 1-on-1 support for you as you wade through the logistics of managing a loss.\n
              After requesting a consult, your Guide will reach out to schedule a free initial conversation to learn more about your situation and how they can help.\n
              Request your consult by clicking the button below.`}
            confirmButtonText={`${
              showSuccessMessage ? "Request Sent" : button_text.text
            }`}
            cancelButtonText="Cancel"
            confirmButtonDisabled={showSuccessMessage}
            onConfirm={() => {
              updateOnboarded({ variables: { plan_id: planId } }).then(() => {
                setShowConfirmModal(false)
                setShowSuccessMessage(true)
              })
            }}
            onCancel={() => setShowConfirmModal(false)}
          />
        )}
      </div>
    </div>
  )
}

Onboarding.propTypes = {
  planId: PropTypes.string.isRequired,
}

export default Onboarding
